export type AutoScale = {
    storage: {
        enabled: boolean;
    };
};

export type DataStoreSpecClassProps = {
    autoscale: AutoScale;
};

export default class DataStoreSpec {
    readonly autoscale: AutoScale;

    constructor(props: DataStoreSpecClassProps) {
        this.autoscale = props.autoscale;
    }
}
