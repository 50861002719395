import { ReactElement } from 'react';
import styles from './AppHeader.module.less';
import { Layout } from 'antd';
import AppLogo from './AppLogo';
import AppCreateButton from './AppCreateButton';
import AppUserMenu from './AppUserMenu';
import { userMenuDisabled } from '../../core/CcxEnv';
import AppHeaderNotifications from './AppHeaderNotifications';
import { useAppSelector } from '../../redux/hooks';

interface Props {
    testId?: string;
}

function AppHeader({ testId = 'AppHeader' }: Props): ReactElement {
    const { Header } = Layout;

    const { user } = useAppSelector((state) => state.user);

    return (
        <Header className={styles.AppHeader} data-testid={testId}>
            <div>
                <AppLogo user={user} />
            </div>
            <div>
                {!user?.isAdmin && <AppCreateButton />}
                <AppHeaderNotifications />
                {!userMenuDisabled && <AppUserMenu />}
            </div>
        </Header>
    );
}

export default AppHeader;
