import { notification, Modal } from 'antd';
import styles from './DbDatabase.module.less';
import AppEmpty from '../AppEmpty';
import { useParams } from 'react-router-dom';
import useDatabases from '../../core/hooks/useDatabases';
import AppTable from '../AppTable';
import { StopOutlined } from '@ant-design/icons';
import AddDbDatabase from './AddDbDatabase';
import AppConfirmDialog from '../AppConfirmDialog';
import DbUserService from '../../services/DbUserService';
import AppLoading from '../AppLoading';
import Deployments from '../../types/Deployments';
import Deployment from '../../types/Deployment';
import CcxSectionHeader from '../ccx/common/CcxSectionHeader';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconDeleteOutlined from '../ccx/icons/CcxIconDeleteOutlined';
import DbDatabase from '../../types/DbDatabase';
import { Tooltip } from 'antd';
import LazyLoader from '../LazyLoader';
import { DatabaseType } from '../../types/Db';
import numbro from 'numbro';
import useCmonStatus from '../../core/hooks/useCmonStatus';
import { isRestoringDatastore } from '../../core/helpers';
import { useCallback, useEffect, useState } from 'react';
import useDataStoreJobs from '../../core/hooks/useDataStoreJobs';

interface UrlProps {
    dataStoreUuid: string;
    projectUuid: string;
    tab: string;
}

type DatabasesProps = {
    deployments?: Deployments;
    deployment?: Deployment;
};

const Databases = ({ deployments }: DatabasesProps) => {
    const { dataStoreUuid } = useParams<UrlProps>();

    const currentDeployment = deployments?.getByUuid(dataStoreUuid);

    const {
        databases,
        refresh: dbRefresh,
        loading: dbLoading,
    } = useDatabases(dataStoreUuid);

    const { status } = useCmonStatus();

    const { jobs } = useDataStoreJobs({
        dataStoreUuid: currentDeployment?.dataStoreUuid,
    });

    const [message, setMessage] = useState<string>(
        'No databases have been created yet.'
    );

    const checkRestoringBackup = useCallback(() => {
        if (!status?.isFunctional()) {
            return setMessage(
                'Cannot list databases, the datastore is not available at the moment.'
            );
        }

        if (isRestoringDatastore(jobs) || !databases) {
            return setMessage('Cannot list databases during backup restore.');
        }

        if (databases.databases.length === 0) {
            return setMessage('No databases have been created yet.');
        }
    }, [status, databases, jobs]);

    useEffect(() => {
        checkRestoringBackup();
    }, [jobs, checkRestoringBackup, status]);

    const databasesColumns = [
        {
            title: 'Database Name',
            dataIndex: 'databaseName',
            key: 'databaseName',
        },
        {
            title: 'Size',
            key: 'databaseSize',
            render: (text: string, record: any) => {
                return record.databaseSize
                    ? numbro(parseInt(record.databaseSize, 10)).format({
                          output: 'byte',
                          base: 'binary',
                          spaceSeparated: true,
                          mantissa: 1,
                      })
                    : '0';
            },
        },
        {
            title: 'Tables',
            key: 'numberOfTables',
            render: (text: string, record: any) => {
                return record.numberOfTables ? record.numberOfTables : '0';
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 90,
            render: (text: string, record: DbDatabase) => {
                const onConfirmDelete = () => {
                    return new Promise(async (resolve, reject) => {
                        notification.open({
                            message: 'Remove database',
                            description: 'The database will be removed soon.',
                            icon: <CcxIconInfoCircleTwoTone />,
                        });

                        Modal.destroyAll();

                        try {
                            const data = {
                                uuid: dataStoreUuid,
                                dbName: record.databaseName,
                            };
                            await DbUserService.deleteDatabase(data);

                            dbRefresh();

                            resolve(null);
                        } catch (e) {
                            notification.open({
                                message: 'Remove database',
                                description: `There was an error removing the database. ${e}`,
                                icon: (
                                    <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                ),
                            });

                            console.error(e);

                            reject();
                        }
                    }).catch(() => console.log('Oops errors!'));
                };

                return (
                    <div className={styles.DbDatabaseOptionsColumn}>
                        {record.isDeleting() ? (
                            <Tooltip
                                placement="top"
                                title="Database is deleting"
                            >
                                <LazyLoader />
                            </Tooltip>
                        ) : (
                            <>
                                {record.isDefaultDatabase() ? (
                                    <Tooltip
                                        placement="top"
                                        title="Default database cannot be deleted"
                                    >
                                        <StopOutlined
                                            className={styles.DbUsersStop}
                                        />
                                    </Tooltip>
                                ) : currentDeployment?.isMSSQL() ? (
                                    <StopOutlined
                                        className={styles.DbUsersStop}
                                    />
                                ) : (
                                    <AppConfirmDialog
                                        critical={true}
                                        title={'Dropping database'}
                                        onOk={onConfirmDelete}
                                        content={`Are you sure you want to drop database "${record.databaseName}"? WARNING! All data in the database will be lost.`}
                                        actionIcon={<CcxIconDeleteOutlined />}
                                    />
                                )}
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            {(currentDeployment && (
                <>
                    <CcxSectionHeader>
                        <>
                            {currentDeployment?.isMSSQL() ? undefined : (
                                <AddDbDatabase
                                    onSuccess={dbRefresh}
                                    uuid={dataStoreUuid}
                                    currentDeployment={currentDeployment}
                                    type={
                                        (currentDeployment?.getServiceName() as DatabaseType) ||
                                        DatabaseType.UNKNOWN
                                    }
                                />
                            )}
                        </>
                    </CcxSectionHeader>
                    <h4 className={styles.DbDatabaseLabel}>Databases</h4>

                    {dbLoading ? (
                        <AppLoading />
                    ) : databases && databases?.databases.length > 0 ? (
                        <AppTable
                            columns={databasesColumns}
                            data={databases?.databases}
                            rowKey="uniqueKey"
                            pagination={{
                                hideOnSinglePage: true,
                            }}
                        />
                    ) : (
                        <AppEmpty message={message} />
                    )}
                </>
            )) || <AppEmpty message="No datastore was retrieved" />}
        </div>
    );
};

export default Databases;
